import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import {
  PlusIcon,
  PencilIcon,
  PlayIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/outline";
import EditActionItemDrawer from "./EditActionItemDrawer";
var moment = require("moment");

let pollMeetingDelay = 500;
let pollMeetingIntervalIDNonReactive = null;

const MeetingData = (props) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const openedFromMeet = query.get("openedFromMeet");
  const [pollMeetingIntervalID, setPollMeetingIntervalID] = useState(null);

  const [copyActionItemsButtonText, setCopyActionItemsButtonText] =
    useState("Copy");
  const [copyNotesButtonText, setCopyNotesButtonText] = useState("Copy Notes");

  const [isExpand, setIsExpand] = useState(true);
  const [topics, setTopics] = useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [isLegacyActionItems, setIsLegacyActionItems] = useState(false);

  // New state for drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedActionItem, setSelectedActionItem] = useState(null);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const lauremIpsumArray = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    "Curabitur consequat velit in mi scelerisque",
    "pulvinar tristique nisi vestibulum",
    "Integer vulputate ipsum sed eros eleifend tincidunt",
    "Integer metus tellus, pretium vel sodales in",
    "iaculis ac dui. Integer rutrum",
  ];

  const checkForMeetingData = useCallback(
    async (uuid, userEmail) => {
      try {
        const enhancedRecResponse = await fetch(
          `https://backend.scribbl.co/enhanced-recording/${uuid}?email=${userEmail}&uuid=${uuid}`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (!enhancedRecResponse.ok) {
          throw new Error("Failed to fetch meeting data");
        }

        const enhancedRecording = await enhancedRecResponse.json();
        let meetingData = null;
        console.log("enhancedRecording!!!", enhancedRecording);

        try {
          meetingData =
            typeof enhancedRecording.gptMeetingData === "string"
              ? JSON.parse(enhancedRecording.gptMeetingData)
              : enhancedRecording.gptMeetingData;
        } catch (error) {
          console.log("couldn't parse meeting data, continuing");
        }

        if (meetingData?.topics) {
          props.setMeetingData(meetingData);
          props.setRecording && props.setRecording(enhancedRecording);

          if (enhancedRecording.actionItems?.length > 0) {
            console.log("has action items!!!", enhancedRecording.actionItems);
            props.setActionItems &&
              props.setActionItems(enhancedRecording.actionItems);
          }

          clearInterval(pollMeetingIntervalIDNonReactive);
          setPollMeetingIntervalID(null);
          pollMeetingIntervalIDNonReactive = null;
        } else if (pollMeetingDelay < 60000) {
          pollMeetingDelay = pollMeetingDelay * 2;

          clearInterval(pollMeetingIntervalIDNonReactive);
          const intervalID = setInterval(
            () => checkForMeetingData(uuid, userEmail),
            pollMeetingDelay
          );
          setPollMeetingIntervalID(intervalID);
          pollMeetingIntervalIDNonReactive = intervalID;
        } else {
          clearInterval(pollMeetingIntervalIDNonReactive);
          setPollMeetingIntervalID(null);
          pollMeetingIntervalIDNonReactive = null;
        }
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        clearInterval(pollMeetingIntervalIDNonReactive);
        setPollMeetingIntervalID(null);
        pollMeetingIntervalIDNonReactive = null;
      }
    },
    [pollMeetingIntervalID]
  );

  useEffect(() => {
    if (
      openedFromMeet &&
      Object.keys(props.meetingData).length === 0 &&
      props.user.settingAIMeetingNotes
    ) {
      const intervalID = setInterval(
        () => checkForMeetingData(props.meetingUUID, props.userEmail),
        pollMeetingDelay
      );
      setPollMeetingIntervalID(intervalID);
      pollMeetingIntervalIDNonReactive = intervalID;

      return () => {
        clearInterval(pollMeetingIntervalIDNonReactive);
      };
    }
  }, []);

  useEffect(() => {
    if (props.topics) {
      props.topics.forEach((topic, index) => {
        topic.isOpen = false;
        topic.index = index;
      });
      setTopics(props.topics);
    }
  }, [props.topics]);

  useEffect(() => {
    if (props.actionItems && props.actionItems.length > 0) {
      // Filter out deleted items before setting the state
      const nonDeletedItems = props.actionItems.filter(
        (item) => !item.DeletedAt
      );
      setActionItems(nonDeletedItems);
      setIsLegacyActionItems(false);
    } else if (props.meetingData?.action_items?.length > 0) {
      const mappedItems = props.meetingData.action_items.map((item) => ({
        ID: item.id,
        title: item.action_item,
        assignee:
          item.person_responsible && item.person_responsible.length > 0
            ? item.person_responsible.join(", ")
            : "Not specified",
        due_date: item.due_date,
        start_time: item.time,
        hasClip: true,
      }));
      setActionItems(mappedItems);
      setIsLegacyActionItems(true);
    }
  }, [props.actionItems, props.meetingData]);

  useEffect(() => {
    if (topics) {
      let expand = true;
      topics.forEach((topic) => {
        if (topic.isOpen) {
          expand = false;
        }
      });
      setIsExpand(expand);
    }
  }, [topics]);

  const turnOnAIMeetingData = async () => {
    const intervalID = setInterval(
      () => checkForMeetingData(props.meetingUUID, props.userEmail),
      pollMeetingDelay
    );
    setPollMeetingIntervalID(intervalID);
    pollMeetingIntervalIDNonReactive = intervalID;

    setLoading(true);
    await fetch(
      `https://backend.scribbl.co/enhanced-recording/${props.meetingUUID}/turn-on-ai-meeting-data`,
      {
        method: "POST",
        body: JSON.stringify({
          email: props.userEmail,
        }),
        credentials: "include",
      }
    );
  };

  // Keep your existing Topics JSX, we'll just update the Action Items section

  return (
    <div>
      {/* Topics section remains unchanged */}
      <div className="m-3 rounded-md bg-white flex px-4 py-3 border border-gray-200 sm:px-6">
        <div className="w-full -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          {topics && topics.length > 0 && (
            <div className="w-full ml-4 mt-2">
              <div className="w-full justify-between flex items-center">
                <div className="flex items-center">
                  <h3 className="text-lg ml-[6px] leading-6 font-medium text-gray-900">
                    Meeting Notes
                  </h3>
                  <button
                    className="ml-2 expandAll"
                    onClick={() => {
                      const forClipboard = formatNotesForClipboard(
                        topics,
                        props.meetingTitle
                      );
                      navigator.clipboard.writeText(forClipboard);
                      setCopyNotesButtonText("Copied!");
                      setTimeout(() => {
                        setCopyNotesButtonText("Copy Notes");
                      }, 1000);
                    }}
                  >
                    {copyNotesButtonText}
                  </button>
                </div>

                {isExpand ? (
                  <button
                    className="expandAll"
                    onClick={() => {
                      topics.forEach((topic) => {
                        topic.isOpen = true;
                      });
                      const updatedTopics = structuredClone(topics);
                      setTopics(updatedTopics);
                    }}
                  >
                    Expand All
                  </button>
                ) : (
                  <button
                    className="expandAll"
                    onClick={() => {
                      topics.forEach((topic) => {
                        topic.isOpen = false;
                      });
                      const updatedTopics = structuredClone(topics);
                      setTopics(updatedTopics);
                    }}
                  >
                    Collapse All
                  </button>
                )}
              </div>

              {/* Summary topics */}
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingTop: "8px",
                  flex: "none",
                  order: 1,
                  flexGrow: 0,
                }}
                className="list-disc text-sm text-gray-700"
              >
                {topics &&
                  topics.map((topic, index) => {
                    return (
                      <div className="noteItem" key={index}>
                        <div className="outerWrapper">
                          <div className="innerWrapper w-full">
                            {topic.isOpen === true ? (
                              <button
                                className="toggleWrapper"
                                onClick={() => {
                                  topics[topic.index].isOpen = false;
                                  const updatedTopics = structuredClone(topics);
                                  setTopics(updatedTopics);
                                }}
                              >
                                <svg
                                  className="toggleButton"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13 5.5L8 10.5L3 5.5"
                                    stroke="#4B5563"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  topics[topic.index].isOpen = true;
                                  const updatedTopics = structuredClone(topics);
                                  setTopics(updatedTopics);
                                }}
                                className="toggleWrapper"
                              >
                                <svg
                                  className="toggleButton"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.5 3L10.5 8L5.5 13"
                                    stroke="#4B5563"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            )}
                            <button
                              className="topicText text-left"
                              onClick={() => {
                                props.topicClicked(topic);
                              }}
                            >
                              {topic.topic} (
                              {formatTimeFromSeconds(topic.start)}-{" "}
                              {formatTimeFromSeconds(topic.end)})
                              {props.isVideoMeeting && (
                                <svg
                                  className="w-4 h-4 ml-2 playTopic"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  style={{
                                    flexShrink: 0,
                                  }}
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                                  />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                        {topic.isOpen && (
                          <div className="listWrapper">
                            <ul className="list-disc pl-3 text-sm text-gray-700">
                              {topic.details.map((detail, index) => {
                                return <li key={index}>{detail}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </ul>
            </div>
          )}
          {!topics ||
            (topics.length === 0 && (
              <div className="w-full ml-4 mt-2">
                <div className="w-full ml-4 mt-2">
                  <div className="w-full justify-between flex items-center">
                    <div className="flex items-center">
                      <h3 className="text-lg ml-[6px] leading-6 font-medium text-gray-900">
                        Meeting Notes
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="relative">
                  {/* has no topics and AI meeting notes are turned off */}
                  {props.user &&
                    !props.user.settingAIMeetingNotes &&
                    !loading &&
                    pollMeetingIntervalID === null && (
                      <div className="absolute h-full w-full px-6 z-10">
                        <div className="text-center">
                          <svg
                            className="h-10 w-10 inline-block mx-auto text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                            />
                          </svg>
                        </div>
                        <div className="text-sm text-center mt-3 text-gray-700">
                          Turn on AI Meetings to get access to powerful AI
                          features like AI generated meeting notes and action
                          items on all of your meetings going forward.
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="button"
                            onClick={turnOnAIMeetingData}
                            className="rounded-md bg-brand-green-lighter2 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-brand-green-lighter3"
                          >
                            Turn on AI Meetings
                          </button>
                        </div>
                      </div>
                    )}
                  {/* Pro user has no topics and AI meeting notes are turned on */}
                  {props.user &&
                    props.user.settingAIMeetingNotes &&
                    props.user.subscription &&
                    !loading &&
                    pollMeetingIntervalID === null && (
                      <div className="absolute h-full w-full px-6 z-10">
                        <div className="text-center">
                          <svg
                            className="h-10 w-10 inline-block mx-auto text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                            />
                          </svg>
                        </div>
                        <div className="text-sm text-center mt-3 text-gray-700">
                          This meeting has no meeting notes, but you will get
                          them on all meetings going forward. Do you want to
                          generate meeting notes for this meeting?
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="button"
                            onClick={turnOnAIMeetingData}
                            className="rounded-md bg-brand-green-lighter2 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-brand-green-lighter3"
                          >
                            Generate AI Meeting Notes
                          </button>
                        </div>
                      </div>
                    )}
                  {/* Free user has no topics and AI meeting notes are turned on */}
                  {props.user &&
                    props.user.settingAIMeetingNotes &&
                    !props.user.subscription &&
                    !loading &&
                    pollMeetingIntervalID === null && (
                      <div className="absolute h-full w-full px-6 z-10">
                        <div className="text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-10 w-10 inline-block mx-auto text-gray-700"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                            />
                          </svg>
                        </div>
                        <div className="text-sm text-center mt-3 text-gray-700">
                          This meeting has no meeting notes, but you will get
                          them on all meetings going forward. Upgrade now to get
                          meeting notes on past meetings.
                        </div>
                        <div className="text-center mt-4">
                          <button
                            type="button"
                            onClick={() => window.open("/pricing", "_blank")}
                            className="rounded-md bg-brand-green-lighter2 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-brand-green-lighter3"
                          >
                            Upgrade Now
                          </button>
                        </div>
                      </div>
                    )}
                  {/* unauthed user with no meeting notes */}
                  {!props.user && (
                    <div className="absolute h-full w-full px-6 z-10">
                      <div className="text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-10 w-10 inline-block mx-auto text-gray-700"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
                          />
                        </svg>
                      </div>
                      <div className="text-sm text-center mt-3 text-gray-700">
                        This meeting has no meeting notes. Sign up for Scribbl
                        today to generate meeting notes on this meeting and all
                        of your future meetings.
                      </div>
                      <div className="text-center mt-4">
                        <button
                          type="button"
                          onClick={() => navigate(`/signup`)}
                          className="rounded-md bg-brand-green-lighter2 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-brand-green-lighter3"
                        >
                          Log In or Sign Up
                        </button>
                      </div>
                    </div>
                  )}
                  {/* loading state */}
                  {(loading || pollMeetingIntervalID !== null) && (
                    <div className="absolute h-full w-full px-6 z-10">
                      <div className="grid place-items-center h-full w-full">
                        <div className="text-center">
                          <svg
                            aria-hidden="true"
                            className="inline-block mx-auto w-8 h-8 text-gray-300 animate-spin fill-brand-green-lighter3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <div className="text-sm text-gray-700 mt-4">
                            Hold tight, our robots are hard at work.
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="text-sm text-gray-700 mt-2 blur select-none">
                    {lauremIpsumArray.map((item, index) => {
                      return (
                        <div className="noteItem" key={index}>
                          <div className="outerWrapper">
                            <div className="innerWrapper w-full">
                              <span className="">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.5 3L10.5 8L5.5 13"
                                    stroke="#4B5563"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className=" text-left">{item}</span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Action Items section */}
      <div className="m-3 rounded-md bg-white flex px-4 py-3 border border-gray-200 sm:px-6">
        <div className="w-full -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          {actionItems &&
            actionItems.filter(
              (item) => item.title && item.title.trim().length > 0
            ).length > 0 && (
              <div className="w-full ml-4 mt-2">
                <div className="w-full flex justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Action Items
                  </h3>
                  <div className="flex gap-2">
                    <button
                      className="expandAll"
                      onClick={() => {
                        const forClipboard = formatActionItemsForClipboard(
                          actionItems,
                          props.meetingTitle,
                          isLegacyActionItems
                        );
                        navigator.clipboard.writeText(forClipboard);
                        setCopyActionItemsButtonText("Copied!");
                        setTimeout(() => {
                          setCopyActionItemsButtonText("Copy");
                        }, 1000);
                      }}
                    >
                      {copyActionItemsButtonText}
                    </button>

                    {!isLegacyActionItems && (
                      <button
                        onClick={() => {
                          setSelectedActionItem(null); // null indicates a new item
                          setIsDrawerOpen(true);
                        }}
                        className="expandAll"
                      >
                        Add Item
                      </button>
                    )}
                  </div>
                </div>

                <div className="actionItemsContainer space-y-2 mt-4">
                  {actionItems
                    .filter(
                      (item) => item.title && item.title.trim().length > 0
                    )
                    .map((item, index) => (
                      <div
                        key={index}
                        className="group flex items-start w-full gap-3 p-3 rounded-md border border-gray-200 bg-white hover:bg-gray-50 cursor-pointer"
                        onClick={() => props.actionItemClicked(item)}
                      >
                        <div className="flex-grow min-w-0">
                          <div className="text-sm text-gray-900">
                            {item.title} (
                            {formatTimeFromSeconds(item.start_time)})
                          </div>
                          <div className="mt-1 text-xs text-gray-500">
                            <span>
                              Assignee:{" "}
                              {isLegacyActionItems
                                ? item.assignee
                                  ? item.assignee
                                  : "No assignee"
                                : item.assignee || "No assignee"}
                            </span>
                            <span className="mx-2">•</span>
                            <span>
                              Due:{" "}
                              {isLegacyActionItems
                                ? item.due_date || "Not specified"
                                : formatDueDate(item.due_date) ||
                                  "Not specified"}
                            </span>
                          </div>
                        </div>

                        <div
                          className="flex gap-2 ml-2"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedActionItem(item);
                              setIsDrawerOpen(true);
                            }}
                            className="p-1.5 text-gray-500 hover:text-brand-green rounded-md transition-all duration-200 hover:-translate-y-0.5 hover:bg-green-50 opacity-0 group-hover:opacity-100"
                          >
                            <PencilIcon className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

          {(!actionItems || actionItems.length === 0) && (
            <div className="w-full ml-4 mt-2">
              <div className="w-full justify-between flex items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Action Items
                </h3>
              </div>
              <div className="relative min-h-[200px] mt-4">
                {(loading || pollMeetingIntervalID !== null) && (
                  <>
                    <div className="absolute inset-0 px-6 z-10">
                      <div className="grid place-items-center h-full w-full">
                        <div className="text-center">
                          <svg
                            aria-hidden="true"
                            className="inline-block mx-auto w-8 h-8 text-gray-300 animate-spin fill-brand-green-lighter3"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <div className="text-sm text-gray-700 mt-4">
                            Hold tight, our robots are hard at work.
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Add the blurred background content */}
                    <div className="text-sm text-gray-700 mt-2 blur select-none">
                      {lauremIpsumArray.map((item, index) => (
                        <div className="noteItem" key={index}>
                          <div className="outerWrapper">
                            <div className="innerWrapper w-full">
                              <span>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M5.5 3L10.5 8L5.5 13"
                                    stroke="#4B5563"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="text-left">{item}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {!loading && pollMeetingIntervalID === null && (
                  <div className="text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
                      />
                    </svg>

                    <h3 className="mt-2 text-sm font-semibold text-gray-900">
                      No Action Items
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      We didn't find any action items for this meeting{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Action Item Drawer */}
      <EditActionItemDrawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedActionItem(null);
        }}
        actionItem={selectedActionItem}
        meetingUUID={props.meetingUUID}
        onActionItemChange={(updatedItems) => setActionItems(updatedItems)}
        currentActionItems={actionItems}
        isLegacyFormat={isLegacyActionItems}
      />
    </div>
  );
};

const formatDueDate = (dateString) => {
  if (!dateString) return "Not specified";

  const date = moment.utc(dateString);
  const today = moment.utc();

  if (date.isSame(today, "day")) return "Today";
  if (date.isSame(today.add(1, "day"), "day")) return "Tomorrow";

  return date.format("MM/DD/YY");
};

function formatTimeFromSeconds(seconds) {
  return moment
    .utc(moment.duration(seconds, "seconds").asMilliseconds())
    .format(
      moment.duration(seconds, "seconds").asHours() >= 1 ? "h:mm:ss" : "m:ss"
    );
}

function formatNotesForClipboard(notes, meetingTitle) {
  const formattedNotes = notes.map((item) => {
    const { details, start, end, topic } = item;
    const startFormatted = formatTimeFromSeconds(start);
    const endFormatted = formatTimeFromSeconds(end);
    const formattedDetails = details
      .map((detail) => `    - ${detail}`)
      .join("\n");
    return `- ${topic} (${startFormatted} - ${endFormatted})\n${formattedDetails}`;
  });
  return `${meetingTitle}\n\nScribbl Recording link: ${
    window.location.href
  }\n${formattedNotes.join("\n\n")}`;
}

function formatActionItemsForClipboard(
  actionItems,
  meetingTitle,
  isLegacyFormat = false
) {
  const validActionItems = actionItems.filter(
    (item) => item.title && item.title.trim().length > 0
  );

  const formatted = validActionItems.map((item) => {
    const { title, assignee, due_date, start_time } = item;
    const persons = isLegacyFormat ? assignee : assignee || "No assignee";
    const timeFormatted = formatTimeFromSeconds(start_time);
    const due = isLegacyFormat
      ? due_date || "Not specified"
      : due_date === "unspecified"
      ? "Not specified"
      : formatDueDate(due_date);

    return `- ${title} (${timeFormatted})\n  Assigned to: ${persons}\n  Due date: ${due}`;
  });

  return `Action items from: ${meetingTitle}\n\nScribbl Recording link: ${
    window.location.href
  }\n${formatted.join("\n")}`;
}

export default MeetingData;
