import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, TrashIcon } from "@heroicons/react/outline";
import moment from "moment";

const EditActionItemDrawer = ({
  isOpen,
  onClose,
  actionItem,
  meetingUUID,
  onActionItemChange,
  currentActionItems,
  isLegacyFormat = false,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    assignee: "",
    due_date: "",
    start_time: 0,
  });
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");

  // Reset form data when drawer opens
  React.useEffect(() => {
    if (actionItem) {
      setFormData({
        title: actionItem.title || "",
        assignee: actionItem.assignee || "",
        due_date: actionItem.due_date
          ? formatDateForInput(actionItem.due_date)
          : "",
        start_time: actionItem.start_time || 0,
      });
    } else {
      // Reset form for new action item
      setFormData({
        title: "",
        assignee: "",
        due_date: "",
        start_time: 0,
      });
    }
    setError("");
  }, [actionItem]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.title.trim()) return;

    setIsSaving(true);
    setError("");

    try {
      if (actionItem) {
        // Edit existing item
        const response = await fetch(
          `https://backend.scribbl.co/action-items/${actionItem.ID}`,
          {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...formData,
              due_date: formData.due_date
                ? moment.utc(formData.due_date).format("YYYY-MM-DD")
                : "",
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to update action item");

        const updatedItem = await response.json();
        onActionItemChange(
          currentActionItems.map((item) =>
            item.ID === actionItem.ID ? updatedItem : item
          )
        );
        onClose();
      } else {
        // Add new item
        const lowestTempId = Math.min(
          0,
          ...currentActionItems.map((item) => item.ID)
        );
        const tempId = lowestTempId - 1;

        // Optimistically add to UI
        const newItem = {
          ID: tempId,
          ...formData,
          start_time: 0,
          hasClip: false,
        };
        onActionItemChange([...currentActionItems, newItem]);

        const response = await fetch(
          `https://backend.scribbl.co/enhanced_recording/${meetingUUID}/action-items`,
          {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              ...formData,
              due_date: formData.due_date
                ? moment.utc(formData.due_date).format("YYYY-MM-DD")
                : "",
            }),
          }
        );

        if (!response.ok) {
          // Rollback optimistic update
          onActionItemChange(
            currentActionItems.filter((item) => item.ID !== tempId)
          );
          throw new Error("Failed to add action item");
        }

        const savedItem = await response.json();
        // Update with the saved item by replacing the temporary one
        onActionItemChange([
          ...currentActionItems.filter((item) => item.ID !== tempId),
          savedItem,
        ]);
        onClose();
      }
    } catch (error) {
      console.error("Error saving action item:", error);
      setError("Failed to save action item. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!actionItem) return;

    try {
      const response = await fetch(
        `https://backend.scribbl.co/action-items/${actionItem.ID}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Failed to delete action item");

      onActionItemChange(
        currentActionItems.filter((item) => item.ID !== actionItem.ID)
      );
      setIsDeleteDialogOpen(false);
      onClose();
    } catch (error) {
      console.error("Error deleting action item:", error);
      setError("Failed to delete action item. Please try again.");
      setIsDeleteDialogOpen(false);
    }
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {actionItem
                              ? isLegacyFormat
                                ? "View Action Item"
                                : "Edit Action Item"
                              : "New Action Item"}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {error && (
                        <div className="px-4 sm:px-6">
                          <div className="rounded-md bg-red-50 p-4">
                            <div className="text-sm text-red-700">{error}</div>
                          </div>
                        </div>
                      )}

                      {isLegacyFormat ? (
                        <div className="px-4 sm:px-6">
                          <div className="rounded-md bg-blue-50 p-4">
                            <div className="text-sm text-blue-700">
                              This action item is from a legacy meeting format
                              and cannot be edited.
                            </div>
                          </div>

                          <div className="mt-6">
                            <div className="text-sm font-medium text-gray-900">
                              Title
                            </div>
                            <div className="mt-1 text-sm text-gray-500">
                              {actionItem?.title}
                            </div>
                          </div>

                          <div className="mt-6">
                            <div className="text-sm font-medium text-gray-900">
                              Assignee
                            </div>
                            <div className="mt-1 text-sm text-gray-500">
                              {actionItem?.assignee || "No assignee"}
                            </div>
                          </div>

                          <div className="mt-6">
                            <div className="text-sm font-medium text-gray-900">
                              Due Date
                            </div>
                            <div className="mt-1 text-sm text-gray-500">
                              {actionItem?.due_date || "Not specified"}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <form onSubmit={handleSubmit} className="flex-1">
                          <div className="flex-1 overflow-y-auto px-4 sm:px-6">
                            <div className="space-y-6 pb-6">
                              <div>
                                <label
                                  htmlFor="title"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Title*
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    id="title"
                                    name="title"
                                    rows={3}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                                    value={formData.title}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        title: e.target.value,
                                      })
                                    }
                                    required
                                    autoFocus
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="assignee"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Assignee
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="assignee"
                                    id="assignee"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                                    value={formData.assignee}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        assignee: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              <div>
                                <label
                                  htmlFor="due_date"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Due Date
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="date"
                                    name="due_date"
                                    id="due_date"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                                    value={formData.due_date}
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        due_date: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-shrink-0 justify-between px-4 py-4">
                            <div>
                              {actionItem && (
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded-md px-3 py-2 text-sm font-medium text-red-600 bg-red-50 hover:bg-red-100 focus:outline-none transition-colors duration-200"
                                  onClick={() => setIsDeleteDialogOpen(true)}
                                >
                                  <TrashIcon className="h-4 w-4 mr-2" />
                                  Delete
                                </button>
                              )}
                            </div>
                            <div className="flex gap-2">
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                                onClick={onClose}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                disabled={isSaving}
                                className="inline-flex justify-center rounded-md border border-transparent bg-brand-green-lighter2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-brand-green-lighter3 focus:outline-none disabled:opacity-50"
                              >
                                {isSaving ? "Saving..." : "Save"}
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition appear show={isDeleteDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setIsDeleteDialogOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Delete Action Item
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-700">
                      Are you sure you want to delete this action item? This
                      cannot be undone.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end gap-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                      onClick={() => setIsDeleteDialogOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-[#E15C54] px-4 py-2 text-sm font-medium text-white hover:bg-[#CA534C] focus:outline-none"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const formatDateForInput = (dateString) => {
  const date = moment.utc(dateString, moment.ISO_8601, true);
  if (!date.isValid()) {
    console.error(
      `Invalid date format: "${dateString}". Please provide a valid ISO 8601 date.`
    );
    return "";
  }
  return date.format("YYYY-MM-DD"); // This will format in UTC
};

export default EditActionItemDrawer;
