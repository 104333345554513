import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useParams, useLocation } from "react-router-dom";
import VideoControls from "./recording/VideoControls";
import SpeakerBars from "./recording/SpeakerBars";
import Transcript from "./recording/Transcript";
import Attributes from "./recording/Attributes";
import MeetingData from "./recording/MeetingData";
import CustomOutput from "./recording/CustomOutput";
import OutOfCredits from "./recording/OutOfCredits";
import TranscriptSearch from "./recording/TranscriptSearch";
import MeetingChat from "./recording/MeetingChat";
import AddMeetingToCollectionModal from "./collections/AddMeetingToCollectionModal";
import AIChat from "./AIChat";
import ShareMeetingModal from "./ShareMeetingModal";
import {
  XIcon,
  ChipIcon,
  ExclamationIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

import ReactTooltip from "react-tooltip";

import amplitude from "amplitude-js";

import * as newuuid from "react-uuid";

import { transcriptSearchChanged } from "../transcript";
import TopBar from "./recording/TopBar";
import { Toast } from "./Toast";

var moment = require("moment");

const MOMENT_BLANK = -1;
const MOMENT_UG_NOTE = 1;
const MOMENT_UG_HIGHLIGHT = 2;
const MOMENT_UG_ACTION_ITEM = 3;
const MOMENT_UG_TOPIC = 4;
const MOMENT_UG_PIN = 5;

const Recording = (props) => {
  let videoPlayerRef = useRef();

  let hoverBoxRef = useRef();

  const overviewRef = useRef();

  const location = useLocation();

  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);

  const { uuid } = useParams();

  const [recording, setRecording] = useState(null);

  // MIKE; ADD DEBOUNCE FOR THE ONMOUSEMOVE STUFF
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [speakerChunksMap, setSpeakerChunksMap] = useState({});

  const [topicChunks, setTopicChunks] = useState([]);

  const [topics, setTopics] = useState([]);

  const [editingName, setEditingName] = useState(false);
  const [editingNameValue, setEditingNameValue] = useState(null);

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareEmails, setShareEmails] = useState([]);
  const [newShareEmails, setNewShareEmails] = useState([]);
  const [shareWithTeam, setShareWithTeam] = useState(false);

  const [startVideoAt, setStartVideoAt] = useState(null);

  const [addCollectionModalOpen, setAddCollectionModalOpen] = useState(false);

  // This is for the EmailEntry component when typing
  // We need access to this for when the modal is closed but there is a valid email in the input
  const copyLinkInitialState = "Copy link";
  const [copyLinkText, setCopyLinkText] = useState(copyLinkInitialState);

  const [tabs, setTabs] = useState([
    { name: "Overview", href: "#", current: true },
    { name: "Transcript", href: "#", current: false },
    { name: "Meeting Chat", href: "#", current: false },
  ]);

  const [searchResults, setSearchResults] = useState([]);
  const [editingTopicIndex, setEditingTopicIndex] = useState(null);
  // going to use a length 2 array for this [start_time, end_time]
  // this makes restoring state of a topic easier when someone cancels an edit
  const [editingTopicTimes, setEditingTopicTimes] = useState([0, 0]);

  /* Delete Topic */
  const [openDeleteTopicModal, setOpenDeleteTopicModal] = useState(false);
  const [topicChunkToDelete, setTopicChunkToDelete] = useState(null);

  const [videoDuration, setVideoDuration] = useState(null);

  const [meetingData, setMeetingData] = useState({});

  const [showCopiedTxToast, setShowCopiedTxToast] = useState(false);

  const [availableHeight, setAvailableHeight] = useState(0);
  const processingBannerRef = useRef(null);
  const tabsRef = useRef(null);
  const txSearchRef = useRef(null);

  const calculateAvailableHeight = () => {
    const prcHeight = processingBannerRef.current?.offsetHeight || 0;
    const tabsHeight = tabsRef.current?.offsetHeight || 0;
    const txSearchHeight = txSearchRef.current?.offsetHeight || 0;

    const totalUsedHeight = prcHeight + tabsHeight + txSearchHeight;
    const availableHeight = window.innerHeight - totalUsedHeight;

    setAvailableHeight(availableHeight);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get("t");
    if (paramValue) {
      console.log("Setting start video at", paramValue);
      setStartVideoAt(paramValue);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setTabs((currentTabs) => {
        // Check if AI Copilot tab already exists
        if (currentTabs.some((tab) => tab.name === "AI Copilot")) {
          return currentTabs;
        }
        // If not, add it
        return [
          ...currentTabs,
          { name: "AI Copilot", href: "#", current: false },
        ];
      });
    } else {
      // Remove AI Copilot tab if user is null
      setTabs((currentTabs) =>
        currentTabs.filter((tab) => tab.name !== "AI Copilot")
      );
    }
  }, [user]);

  // When we get the recording, calculate the speaker chunks
  useEffect(() => {
    if (recording) {
      if (recording.recordingURL) {
        props.setLoading(false);
      }

      const meetingData = recording.gptMeetingData;
      try {
        const jsonMD = JSON.parse(meetingData);
        setMeetingData(jsonMD);
      } catch (error) {
        console.log("couldn't parse meeting data", meetingData);
      }
      // Sort tx items
      recording.transcriptItems.sort((a, b) =>
        a.itemStart > b.itemStart ? 1 : -1
      );

      recording.moments.sort((a, b) => (a.start_time > b.start_time ? 1 : -1));

      const updatedTopics = structureTopicsData(recording, videoDuration);
      setTopics(updatedTopics);
    }

    calculateAvailableHeight();
    window.addEventListener("resize", calculateAvailableHeight);
    return () => {
      window.removeEventListener("resize", calculateAvailableHeight);
    };

    // Create speaker chunks
  }, [recording]);

  useEffect(() => {
    if (topics && recording) {
      const tc = calculateTopicChunks(recording, videoPlayerRef);
      setTopicChunks(tc);
    }
  }, [topics]);

  useEffect(() => {
    //Change copy link text to show that a link was successfully copied.
    if (copyLinkText !== copyLinkInitialState) {
      setTimeout(() => setCopyLinkText(copyLinkInitialState), [1000]);
    }
  }, [copyLinkText]);

  useEffect(() => {
    if (props.userEmail) {
      setUserEmail(props.userEmail);
    }
    if (props.user) {
      setUser(props.user);
    }
  }, [props.userEmail, props.user]);

  useEffect(() => {
    if (props.recording) {
      const cleanedRecording = cleanTranscriptItems(props.recording);
      setRecording(cleanedRecording);
      // Maintain a different object for share settings so that you don't have to update the recording state
      // sharedWithEmails and orgID should go in this new share settings state so we can figure out who it's shared with and if it's shared with the team
      setShareEmails(props.recording.sharedWithEmails);
      setShareWithTeam(props.recording.orgID > 0 || false);
    }
  }, [props.recording]);

  // When the search term changes
  useEffect(() => {
    transcriptSearchChanged(searchTerm, setSearchResults, topics, setTopics);
  }, [searchTerm]);

  useEffect(() => {
    if (editingTopicIndex) {
      setCurrentTab(tabs, setTabs, "Transcript");
    }
  }, [editingTopicIndex]);

  useEffect(() => {
    // I'm only calculating the duration on change here because when the video reloads
    // after delete, we were missing metadata from the video.

    const currentVideoPlayer = videoPlayerRef.current;

    if (currentVideoPlayer && !videoDuration) {
      const setValidDuration = (event) => {
        if (
          currentVideoPlayer.duration &&
          !isNaN(currentVideoPlayer.duration)
        ) {
          setVideoDuration(currentVideoPlayer.duration);
        }
      };

      currentVideoPlayer.addEventListener("loadedmetadata", setValidDuration);
      if (startVideoAt) {
        currentVideoPlayer.currentTime = startVideoAt;
      }

      return () => {
        currentVideoPlayer.removeEventListener(
          "loadedmetadata",
          setValidDuration
        );
      };
    }
  }, [videoPlayerRef.current, videoDuration, setVideoDuration]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const setActionItems = (newActionItems) => {
    setRecording((prevMeeting) => ({
      ...prevMeeting,
      actionItems: newActionItems,
    }));
  };

  function meetingDataTopicClicked(topic) {
    videoPlayerRef.current.currentTime = topic.start;
    videoPlayerRef.current.play();
  }

  function meetingDataActionItemClicked(actionItem) {
    videoPlayerRef.current.currentTime = actionItem.start_time;
    videoPlayerRef.current.play();
  }

  return (
    <div className="w-full h-full">
      <>
        {recording && (
          <div className="h-full overflow-y-auto max-h-screen flex flex-col w-full">
            {/* Recording processing banner */}
            {recording.isProcessing && (
              <div
                ref={processingBannerRef}
                className="bg-brand-green-lighter2"
              >
                <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between flex-wrap">
                    <div className="w-0 flex-1 flex items-center">
                      <span className="flex p-2 rounded-lg bg-brand-green-darker2">
                        <ChipIcon
                          className="h-4 w-4 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      <p className="ml-3 text-white truncate">
                        <span></span>
                        <span className="md:hidden">
                          This video is processing.
                        </span>
                        <span className="hidden md:inline">
                          This video is still processing. You may experience
                          slow loading and some limited functionality.
                          <button
                            type="button"
                            className="inline-flex items-center rounded-md ml-3 bg-brand-green-darker2 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-brand-green"
                            onClick={() => {
                              window.location.reload();
                            }}
                          >
                            <RefreshIcon
                              className="mr-1 h-4 w-4 "
                              aria-hidden="true"
                            />
                            Refresh
                          </button>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col min-w-0 flex-1">
              <div className="flex-1 relative z-0 flex">
                {recording?.isLocked && (
                  <OutOfCredits user={user} userEmail={userEmail} />
                )}
                {/* Start main area*/}
                <main className="flex-1 relative z-0 focus:outline-none overflow-y-auto overflow-x-hidden">
                  <Toast
                    show={showCopiedTxToast}
                    message="Transcript copied to clipboard"
                    onClose={() => setShowCopiedTxToast(false)}
                  />
                  <TopBar
                    meeting={{
                      ...recording,
                      uuid: uuid,
                      meetingTitle: recording.meetingTitle,
                      role: recording.role,
                      googleDocLink: recording.googleDocLink,
                    }}
                    user={user}
                    userEmail={userEmail}
                    onShare={() => setShareModalOpen(true)}
                    onAddToCollection={() => setAddCollectionModalOpen(true)}
                    isPublic={props.isPublic}
                    setMeeting={setRecording}
                    setShowCopiedTxToast={setShowCopiedTxToast}
                  />
                  <div className="relative">
                    <div className="absolute inset-0 py-6 px-4 sm:px-6 lg:px-8">
                      <div className="h-full  rounded-lg">
                        {/* Video section */}
                        <div className="flex flex-col justify-center m-auto">
                          {/* Video */}
                          <video
                            style={{ maxHeight: "55vh" }}
                            controls
                            ref={videoPlayerRef}
                            src={recording.recordingURL}
                            onTimeUpdate={() => {
                              setCurrentVideoTime(
                                videoPlayerRef.current.currentTime
                              );
                            }}
                            onLoadedMetadata={(e) => {
                              setVideoLoaded(true);
                            }}
                          ></video>
                          {/* Playbar section */}
                          <div>
                            {videoLoaded && (
                              <div>
                                <div className="mt-4">
                                  <SpeakerBars
                                    speakerChunksMap={speakerChunksMap}
                                    setSpeakerChunksMap={setSpeakerChunksMap}
                                    videoPlayerRef={videoPlayerRef}
                                    recording={recording}
                                    topicsBar={true}
                                    hoverBoxRef={hoverBoxRef}
                                    topicChunks={topicChunks}
                                    isPublic={props.isPublic}
                                    topics={topics}
                                    setEditingTopicIndex={setEditingTopicIndex}
                                    editingTopicIndex={editingTopicIndex}
                                    setEditingTopicTimes={setEditingTopicTimes}
                                    setTopicChunkToDelete={
                                      setTopicChunkToDelete
                                    }
                                    setOpenDeleteTopicModal={
                                      setOpenDeleteTopicModal
                                    }
                                    videoDuration={videoDuration}
                                  />
                                </div>
                                <div className="mt-4">
                                  <VideoControls
                                    videoPlayerRef={videoPlayerRef}
                                    setCurrentTab={setCurrentTab}
                                    tabs={tabs}
                                    setTabs={setTabs}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
                {/* End main area */}

                {/* Start secondary column (hidden on smaller screens) */}
                {/*<aside className="hidden bg-gray-100 relative xl:flex xl:flex-col flex-shrink-0 w-5/12 border-l border-gray-200 overflow-y-auto">*/}
                <aside className="z-[-1] overflow-y-auto hidden bg-gray-50 relative md:flex md:flex-col flex-shrink-0 w-5/12 border-l border-gray-200">
                  <div className="absolute inset-0 px-1">
                    <div className="h-full  rounded-lg">
                      {
                        <div>
                          {/* Search bar */}
                          <div ref={txSearchRef} className="px-3 py-2">
                            <TranscriptSearch
                              searchResults={searchResults}
                              searchTerm={searchTerm}
                              setSearchTerm={setSearchTerm}
                              topics={topics}
                              setTopics={setTopics}
                              setCurrentTab={setCurrentTab}
                              tabs={tabs}
                              setTabs={setTabs}
                            />
                          </div>
                          {/* Tabs */}
                          <div ref={tabsRef} className="px-3">
                            <div className="hidden sm:block">
                              <div className="border-b border-gray-200">
                                <nav
                                  className="-mb-px flex space-x-8"
                                  aria-label="Tabs"
                                >
                                  {tabs.map(
                                    (tab) =>
                                      ((props?.isPublic &&
                                        tab.name !== "Clips") ||
                                        !props?.isPublic) && (
                                        <a
                                          key={tab.name}
                                          className={classNames(
                                            tab.current
                                              ? "border-brand-green-lighter3 text-brand-green"
                                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-brand-green-lighter3",
                                            "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                                          )}
                                          aria-current={
                                            tab.current ? "page" : undefined
                                          }
                                          onClick={() => {
                                            setCurrentTab(
                                              tabs,
                                              setTabs,
                                              tab.name
                                            );
                                            var eventProperties = {
                                              recording_uuid: uuid,
                                              to_tab: tab.name,
                                            };
                                            amplitude
                                              .getInstance()
                                              .logEvent(
                                                "er toggle recording tab",
                                                eventProperties
                                              );
                                          }}
                                        >
                                          {tab.name}
                                        </a>
                                      )
                                  )}
                                </nav>
                              </div>
                            </div>
                          </div>
                          {/* Overview Section */}
                          {tabs[0].current && (
                            <div ref={overviewRef}>
                              {/* Attributes Card */}

                              {videoPlayerRef.current && (
                                <Attributes
                                  meetingCreatedAt={recording.CreatedAt}
                                  meetingDuration={
                                    videoPlayerRef.current.duration
                                      ? (videoPlayerRef.current.duration -
                                          recording.recordingTimeOffset) *
                                        1000
                                      : null
                                  }
                                  attendees={Object.keys(speakerChunksMap)}
                                />
                              )}

                              {user && <CustomOutput meeting={recording} />}

                              {/* GPT Notes */}
                              <MeetingData
                                user={user}
                                topics={meetingData.topics}
                                actionItems={recording.actionItems}
                                meetingTitle={recording.meetingTitle}
                                topicClicked={meetingDataTopicClicked}
                                actionItemClicked={meetingDataActionItemClicked}
                                isVideoMeeting={true}
                                userEmail={userEmail}
                                meetingUUID={recording.uuid}
                                meetingData={meetingData}
                                setMeetingData={setMeetingData}
                                setRecording={setRecording}
                                setActionItems={setActionItems}
                              />

                              {/* Deprecated Summaries (only show if we don't have meeting data) */}
                              {Object.keys(meetingData).length === 0 &&
                                recording.summarySafety !== 2 &&
                                recording.summary && (
                                  <div className="m-3 rounded-md bg-white flex px-4 py-3 border border-gray-200 sm:px-6">
                                    <div className="w-full -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                                      <div className="w-full ml-4 mt-2">
                                        <div className="w-full flex">
                                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Meeting Summary (
                                            {moment(
                                              new Date(recording.CreatedAt)
                                            ).format("MM/DD/YYYY")}
                                            )
                                          </h3>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="ml-2 h-4 w-4 cursor-pointer"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            data-tip="This summary was generated by a system using A.I."
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                          </svg>
                                          <ReactTooltip />
                                        </div>

                                        <ul className="list-disc pl-3 pt-1 text-sm text-gray-700">
                                          {recording.summary
                                            .replaceAll("\n-", "- ")
                                            .replaceAll("  ", " ")
                                            .split("- ")
                                            .map((bulletPoint, index) => {
                                              return (
                                                bulletPoint.trim() && (
                                                  <li key={index}>
                                                    {bulletPoint}
                                                  </li>
                                                )
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                          {/* Transcript section */}
                          {tabs[1].current && (
                            <Transcript
                              currentVideoTime={currentVideoTime}
                              userEmail={userEmail}
                              recording={recording}
                              setRecording={setRecording}
                              searchTerm={searchTerm}
                              setSearchTerm={setSearchTerm}
                              topics={topics}
                              setTopics={setTopics}
                              setTopicChunks={setTopicChunks}
                              structureTopicsData={structureTopicsData}
                              calculateTopicChunks={calculateTopicChunks}
                              videoPlayerRef={videoPlayerRef}
                              topicsPage={false}
                              isPublic={props.isPublic}
                              addNewTopic={addNewTopic}
                              editingTopicIndex={editingTopicIndex}
                              setEditingTopicIndex={setEditingTopicIndex}
                              setEditingTopicTimes={setEditingTopicTimes}
                              editingTopicTimes={editingTopicTimes}
                              videoDuration={videoDuration}
                            />
                          )}
                          {/* Meeting Chat section */}
                          {tabs[2].current && (
                            <MeetingChat
                              chatMessages={recording.chatMessages}
                            />
                          )}
                          {tabs[3] && tabs[3].current && user && (
                            <AIChat
                              uuid={uuid}
                              userEmail={userEmail}
                              initialAIChat={recording.aiChat}
                              maxHeight={availableHeight + "px"}
                              isProUser={
                                user.subscription &&
                                user.subscription.accountType !== 0
                              }
                            />
                          )}
                        </div>
                      }
                    </div>
                  </div>
                </aside>
                {/* End secondary column */}
              </div>
            </div>
          </div>
        )}
        {/* Share recording modal */}
        {recording && user && (
          <ShareMeetingModal
            isOpen={shareModalOpen}
            onClose={() => {
              setShareModalOpen(false);
            }}
            user={user}
            userEmail={user.email}
            uuid={recording.uuid}
            key={recording.uuid}
            setTriggerTeamModal={props.setTriggerTeamModal}
            currentVideoTime={currentVideoTime}
          />
        )}
        <AddMeetingToCollectionModal
          isOpen={addCollectionModalOpen}
          onClose={() => setAddCollectionModalOpen(false)}
          meetingId={recording?.ID}
        />
        {/* Delete Topic Modal */}
        <Transition.Root show={openDeleteTopicModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setOpenDeleteTopicModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                      onClick={() => setOpenDeleteTopicModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete Topic
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete{" "}
                          {topicChunkToDelete && topicChunkToDelete.name}?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        if (topicChunkToDelete) {
                          deleteTopic(topicChunkToDelete.topicUUID);
                          var eventProperties = {
                            recording_uuid: recording.uuid,
                            topic_uuid: topicChunkToDelete.topicUUID,
                          };
                          amplitude
                            .getInstance()
                            .logEvent("delete topic", eventProperties);
                        }
                        setOpenDeleteTopicModal(false);
                        setTopicChunkToDelete(null);
                      }}
                    >
                      Delete Topic
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpenDeleteTopicModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    </div>
  );

  function addNewTopic(recording, startTime) {
    // push to recording.moments
    const newID = `new-topic-${newuuid()}`;
    recording.moments.push({
      ID: newID,
      identifier: "new-topic",
      start_time: startTime,
      end_time: startTime,
      isTopic: true,
      transcriptItems: [],
      name: "new-topic",
      open: true,
      creatingTopic: true,
      type: MOMENT_UG_TOPIC,
    });

    // sort recording.moments
    recording.moments.sort((a, b) => (a.start_time > b.start_time ? 1 : -1));

    const updatedRecording = structuredClone(recording);

    const updatedTopics = structureTopicsData(updatedRecording, videoDuration);

    let returnIndex = -1;
    updatedTopics.forEach((t, index) => {
      if (t.identifier === "new-topic") {
        returnIndex = index;
        t.creatingTopic = true;
      }
    });

    setTopics(updatedTopics);

    updatedRecording.moments.forEach((m) => {
      if (m.identifier === "new-topic") {
        m.transcriptItems = updatedTopics[returnIndex].transcriptItems;
      }
    });

    const newTC = calculateTopicChunks(updatedRecording, videoPlayerRef);

    setTopicChunks(newTC);
    return { indexOfNewTopic: returnIndex, momentID: newID };
  }

  async function deleteTopic(topicUUID) {
    try {
      // put fetch here
      const deleteTopicResoponseRaw = await fetch(
        `https://backend.scribbl.co/topics/${topicUUID}/delete`,
        {
          method: "POST",
          body: JSON.stringify({
            email: userEmail,
          }),
          credentials: "include", // This line ensures cookies are sent with the request
        }
      );

      let deleteIndex;
      let recordingClone = recording;
      recordingClone.moments.forEach((moment, index) => {
        if (moment.identifier === topicUUID) {
          deleteIndex = index;
        }
      });

      if (deleteIndex) {
        recordingClone.moments.splice(deleteIndex, 1);
        setRecording(recordingClone);
        const updatedTopics = structureTopicsData(recording, videoDuration);
        setTopics(updatedTopics);
      }
    } catch (exception) {
      console.log(exception);
    }
  }
};

export default Recording;

async function copySharedRecordingToClipboard(
  recordingUUID,
  currentVideoTime = null
) {
  if (!navigator.clipboard) {
    console.log("clipboard copy not supported by browser");
    // Clipboard API not available
    return;
  }
  try {
    let url = `${window.location.protocol}//${window.location.host}/share/meeting/${recordingUUID}`;
    if (currentVideoTime) {
      url += `?t=${currentVideoTime}`;
    }
    await navigator.clipboard.writeText(url);
  } catch (err) {
    console.log("failed to copy link:" + err);
  }
}

function cleanTranscriptItems(recording) {
  let newTxItems = [];
  recording.transcriptItems.forEach((txItem) => {
    if (txItem.itemStart - recording.recordingTimeOffset >= 0) {
      newTxItems.push(txItem);
    }
  });
  recording.transcriptItems = newTxItems;
  return recording;
}

const shareRecording = async (
  uuid,
  userEmail,
  shareWithTeam,
  shareEmails,
  setShareWithTeam,
  setShareEmails
) => {
  // put fetch here
  const shareResp = await fetch(
    `https://backend.scribbl.co/enhanced-recording/${uuid}/share/update?email=${userEmail}&uuid=${uuid}`,
    {
      method: "POST",
      body: JSON.stringify({
        sharedWithEmails: shareEmails,
        sharedWithTeam: shareWithTeam,
      }),
      credentials: "include", // This line ensures cookies are sent with the request
    }
  );
  const shareResponse = await shareResp.json();
  setShareWithTeam(shareResponse.sharedWithTeam);
  setShareEmails(shareResponse.sharedWithEmails);
  var eventProperties = {
    recording_uuid: uuid,
    users_shared_with: shareEmails,
    shared_with_team: shareWithTeam,
    number_users_shared_with: shareEmails.length,
  };
  amplitude.getInstance().logEvent("er recording shared", eventProperties);
};

const getShareLink = async (uuid, userEmail, currentVideoTime = null) => {
  const shareRecordingResponse = await fetch(
    `https://backend.scribbl.co/enhanced-recording/${uuid}/share`,
    {
      method: "POST",
      body: JSON.stringify({
        email: userEmail,
      }),
      credentials: "include", // This line ensures cookies are sent with the request
    }
  );
  const respJson = await shareRecordingResponse.json();
  copySharedRecordingToClipboard(respJson.uuid, currentVideoTime);
};

const setCurrentTab = (tabs, setTabs, tabName) => {
  let newTabs = [...tabs];
  newTabs.forEach((t) => {
    if (tabName === t.name) {
      t.current = true;
    } else {
      t.current = false;
    }
  });
  setTabs(newTabs);
};

function structureTopicsData(recording, recordingDuration) {
  const topics = [];
  let offset;

  let moments = [];
  recording.moments.forEach((m) => {
    if (m.type === MOMENT_UG_TOPIC) {
      moments.push(m);
    }
  });

  if (recording.recordingTimeOffset) {
    offset = recording.recordingTimeOffset;
  } else {
    offset = recording.start_time;
  }

  clearAlreadyAddedForTxItems(recording);

  // Create topic buckets
  // handle first blank section

  let firstTopicEnd;
  if (moments.length) {
    firstTopicEnd = moments[0].start_time - offset;
  } else {
    firstTopicEnd = recordingDuration;
  }

  topics.push({
    start: 0,
    end: firstTopicEnd,
    isTopic: false,
    transcriptItems: [],
    open: true,
    type: -1,
  });
  // handle topic
  moments.forEach((topic, index) => {
    topics.push({
      id: topic.ID,
      identifier: topic.identifier,
      start: topic.start_time - offset,
      end: topic.end_time - offset,
      time: topic.time,
      isTopic: true,
      transcriptItems: [],
      name: topic.name,
      open: true,
      creatingTopic: topic.creatingTopic,
      type: topic.type,
    });
    // if next topic handle next blank section using next topic end
    if (moments[index + 1]) {
      // else handle last blank section using recording end
      topics.push({
        start: topic.end_time - offset,
        end: moments[index + 1].start_time - offset,
        time: topic.time,
        isTopic: false,
        transcriptItems: [],
        open: true,
        type: MOMENT_BLANK,
      });
    } else {
      if (topic.end_time - offset <= recordingDuration) {
        topics.push({
          start: topic.end_time - offset,
          end: recordingDuration,
          isTopic: false,
          transcriptItems: [],
          open: true,
          type: MOMENT_BLANK,
        });
      }
    }
  });

  // Fill buckets with txItems.
  // 2 rounds: topics first, non topics second
  recording.transcriptItems.forEach((txItem) => {
    let txItemInTopic = false;
    let topicIn = null;
    if (!txItem.alreadyAdded) {
      topics.forEach((topic) => {
        if (
          txItem.itemStart - offset <= topic.end &&
          txItem.itemStart - offset >= topic.start &&
          topic.isTopic
        ) {
          txItemInTopic = true;
          topicIn = topic;
        }
      });
      if (txItemInTopic) {
        const txItemClone = structuredClone(txItem);
        topicIn.transcriptItems.push(txItemClone);
        txItem.alreadyAdded = true;
      }
    }
  });
  recording.transcriptItems.forEach((txItem) => {
    let txItemInTopic = false;
    let topicIn = null;
    if (!txItem.alreadyAdded) {
      topics.forEach((topic) => {
        if (
          txItem.itemStart - offset < topic.end &&
          txItem.itemStart - offset >= topic.start &&
          topic.isTopic === false
        ) {
          txItemInTopic = true;
          topicIn = topic;
        }
      });
      if (txItemInTopic) {
        const txItemClone = structuredClone(txItem);
        topicIn.transcriptItems.push(txItemClone);
        txItem.alreadyAdded = true;
      }
    }
  });
  return topics;
}

function clearAlreadyAddedForTxItems(recording) {
  recording.transcriptItems.forEach((txItem) => {
    txItem.alreadyAdded = false;
  });
}

export function calculateTopicChunks(recording, videoPlayerRef) {
  let topicChunks = [];

  const topicMoments = [];

  recording.moments?.forEach((moment) => {
    if (moment.type === 4) {
      topicMoments.push(moment);
    }
  });

  topicMoments.forEach((moment) => {
    const firstTI = moment.start_time;
    let lastTI = moment.end_time;
    if (lastTI - firstTI === 0) {
      lastTI++;
    }

    topicChunks.push({
      start: firstTI - recording.recordingTimeOffset,
      finish: lastTI - recording.recordingTimeOffset,
      type: "speaking",
      name: moment.name ? moment.name : "Unnamed Topic",
      topicUUID: moment.identifier,
      creatingTopic: moment.creatingTopic || false,
    });
    // }
  });

  // Sort by start time
  topicChunks.sort((a, b) => (a.start > b.start ? 1 : -1));

  // go back and fill in empty spots
  let currentPosition = 0;

  for (let i = 0; i < topicChunks.length; i++) {
    const chunkInProgress = topicChunks[i];

    if (chunkInProgress.start > currentPosition) {
      // add a chunk prior
      topicChunks.splice(i, 0, {
        start: currentPosition,
        finish: chunkInProgress.start,
        type: "blank",
        creatingTopic: false,
      });
      i = i - 1;
    }

    // if last item, check if end spot needed
    if (
      i === topicChunks.length &&
      chunkInProgress.finish < videoPlayerRef.current.duration
    ) {
      // add one last chunk to the endsz
      topicChunks.push({
        start: chunkInProgress.finish - recording.recordingTimeOffset,
        finish: videoPlayerRef.current.duration,
        type: "blank",
        creatingTopic: false,
      });
    }
    currentPosition = chunkInProgress.finish;
  }
  return topicChunks;
}
