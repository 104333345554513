import React, { useState, useEffect } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardCopyIcon,
} from "@heroicons/react/outline";
import EditActionItemDrawer from "../../recording/EditActionItemDrawer";
import moment from "moment";

const formatTimeFromSeconds = (seconds) => {
  if (!seconds && seconds !== 0) return "0:00";
  return moment
    .utc(moment.duration(seconds, "seconds").asMilliseconds())
    .format(
      moment.duration(seconds, "seconds").asHours() >= 1 ? "h:mm:ss" : "m:ss"
    );
};

const SkeletonLoader = () => (
  <div className="space-y-6">
    {[1, 2, 3].map((i) => (
      <div key={i} className="animate-pulse">
        {/* Meeting header */}
        <div className="flex items-center gap-2 mb-3">
          <div className="w-5 h-5 bg-gray-200 rounded"></div>
          <div className="w-2 h-2 bg-gray-200 rounded-full"></div>
          <div className="h-4 bg-gray-200 rounded w-48"></div>
        </div>

        {/* Action items */}
        <div className="pl-4 border-l-2 border-gray-100 ml-3 space-y-3">
          {[1, 2].map((j) => (
            <div
              key={j}
              className="flex items-start w-full p-3 rounded-md border border-gray-100"
            >
              <div className="flex-grow space-y-2">
                <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                <div className="flex gap-4">
                  <div className="h-3 bg-gray-200 rounded w-32"></div>
                  <div className="h-3 bg-gray-200 rounded w-24"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

const formatDueDate = (dateString) => {
  if (!dateString) return "Not specified";
  return moment.utc(dateString).format("YYYY-MM-DD");
};

export default function ActionItemCollectionTile({ collectionId }) {
  const [recordings, setRecordings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedMeetings, setExpandedMeetings] = useState({});
  const [copyButtonText, setCopyButtonText] = useState("Copy Items");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedActionItem, setSelectedActionItem] = useState(null);

  useEffect(() => {
    const fetchActionItems = async () => {
      try {
        const response = await fetch(
          `https://backend.scribbl.co/collection/${collectionId}/action-items`,
          {
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Process recordings to handle both modern and legacy action items
        const processedData = data.map((recording) => {
          let processedActionItems = [];
          let isLegacy = false;

          // Check for modern action items
          if (recording.actionItems && recording.actionItems.length > 0) {
            processedActionItems = recording.actionItems
              .filter((item) => !item.DeletedAt) // Filter out deleted items
              .map((item) => ({
                ...item,
                startTime: item.startTime || item.start_time || 0,
                isLegacy: false,
              }));
          }
          // Check for legacy action items in gptMeetingData
          else if (recording.gptMeetingData) {
            let meetingData;
            try {
              meetingData =
                typeof recording.gptMeetingData === "string"
                  ? JSON.parse(recording.gptMeetingData)
                  : recording.gptMeetingData;

              if (meetingData?.action_items?.length > 0) {
                isLegacy = true;
                processedActionItems = meetingData.action_items.map((item) => ({
                  ID: item.id,
                  title: item.action_item,
                  assignee:
                    item.person_responsible &&
                    item.person_responsible.length > 0
                      ? item.person_responsible.join(", ")
                      : "Not specified",
                  due_date: item.due_date,
                  startTime: item.time || 0,
                  hasClip: true,
                  isLegacy: true,
                  recordingUUID: recording.uuid, // Add this line
                }));
              }
            } catch (error) {
              console.log(
                "Couldn't parse meeting data for recording:",
                recording.uuid
              );
            }
          }

          const sortedActionItems = processedActionItems.sort((a, b) => {
            const timeA = a.startTime || 0;
            const timeB = b.startTime || 0;
            return timeA - timeB;
          });

          return {
            ...recording,
            actionItems: sortedActionItems,
            isLegacy,
          };
        });

        // Sort meetings by CreatedAt in descending order
        const sortedData = processedData.sort((a, b) => {
          const dateA = new Date(a.CreatedAt);
          const dateB = new Date(b.CreatedAt);
          return dateB - dateA;
        });

        setRecordings(sortedData);
        setExpandedMeetings(
          sortedData.reduce((acc, recording) => {
            acc[recording.uuid] = true;
            return acc;
          }, {})
        );
      } catch (err) {
        console.error("Error in fetchActionItems:", err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (collectionId) {
      fetchActionItems();
    }
  }, [collectionId]);

  const toggleMeeting = (uuid) => {
    setExpandedMeetings((prev) => ({
      ...prev,
      [uuid]: !prev[uuid],
    }));
  };

  const handleCopy = async () => {
    const formattedText = recordings
      .map((recording) => {
        const validItems = recording.actionItems.filter(
          (item) => item.title && item.title.trim().length > 0
        );

        if (!validItems.length) return "";

        const items = recording.actionItems
          .map(
            (item) =>
              `- ${item.title} (${formatTimeFromSeconds(item.startTime)})\n` +
              `  Assigned to: ${item.assignee || "No assignee"}\n` +
              `  Due date: ${formatDueDate(item.due_date)}`
          )
          .join("\n\n");

        return `Meeting: ${recording.meetingTitle}\n${items}`;
      })
      .filter((text) => text)
      .join("\n\n");

    try {
      await navigator.clipboard.writeText(formattedText);
      setCopyButtonText("Copied!");
      setTimeout(() => setCopyButtonText("Copy Items"), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
      setCopyButtonText("Failed to copy");
      setTimeout(() => setCopyButtonText("Copy Items"), 2000);
    }
  };

  const handleActionItemChange = (updatedItems) => {
    setRecordings((prevRecordings) => {
      return prevRecordings.map((recording) => {
        // Get the IDs of all items that still exist
        const existingItemIds = new Set(updatedItems.map((item) => item.ID));

        // Filter out deleted items and update existing ones
        const updatedActionItems = recording.actionItems
          .filter((item) => existingItemIds.has(item.ID))
          .map((item) => {
            const updatedItem = updatedItems.find(
              (updated) => updated.ID === item.ID
            );
            return updatedItem || item;
          });

        const sortedActionItems = updatedActionItems.sort((a, b) => {
          const timeA = a.startTime || 0;
          const timeB = b.startTime || 0;
          return timeA - timeB;
        });

        return {
          ...recording,
          actionItems: sortedActionItems,
        };
      });
    });
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow p-4">
        <div className="bg-white rounded-lg p-4 h-[400px] flex flex-col">
          <div className="flex justify-between items-center mb-4">
            <div className="h-6 bg-gray-200 rounded w-48 animate-pulse"></div>
            <div className="h-8 bg-gray-200 rounded w-24 animate-pulse"></div>
          </div>
          <div className="overflow-y-auto flex-1 pr-2">
            <SkeletonLoader />
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow p-4 h-[400px]">
        <div className="flex items-center justify-center h-full text-brand-red">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="bg-white rounded-lg p-4 h-[400px] flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-semibold text-lg text-gray-800">Action Items</h2>
          <button
            onClick={handleCopy}
            className="inline-flex items-center border border-gray-300 hover:bg-gray-50 text-gray-600 px-3 py-2 rounded-md text-sm font-medium transition-colors duration-150"
          >
            <ClipboardCopyIcon className="h-4 w-4 mr-2" />
            {copyButtonText}
          </button>
        </div>

        <div className="overflow-y-auto flex-1 pr-2 space-y-3">
          {recordings.length > 0 ? (
            recordings.map((recording) => (
              <div key={recording.uuid} className="rounded-lg">
                <div className="flex items-center gap-2 mb-2">
                  <button
                    onClick={() => toggleMeeting(recording.uuid)}
                    className="text-gray-500 hover:text-brand-turq transition-colors"
                  >
                    {expandedMeetings[recording.uuid] ? (
                      <ChevronUpIcon className="h-5 w-5" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" />
                    )}
                  </button>
                  <div className="w-2 h-2 bg-brand-turq rounded-full"></div>
                  <a
                    href={`http://localhost:3000/meeting/${recording.uuid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-medium text-brand-gray-darker1 hover:text-brand-turq flex-grow transition-colors"
                  >
                    {recording.meetingTitle}
                  </a>
                </div>

                {expandedMeetings[recording.uuid] && (
                  <div className="pl-4 border-l-2 border-gray-300 ml-3 space-y-2">
                    {recording.actionItems.filter(
                      (item) => item.title && item.title.trim().length > 0
                    ).length > 0 ? (
                      recording.actionItems
                        .filter(
                          (item) => item.title && item.title.trim().length > 0
                        )
                        .map((item) => (
                          <div
                            key={item.ID}
                            className="group flex items-start w-full p-3 rounded-md bg-white hover:bg-gray-50 cursor-pointer border border-gray-200"
                            onClick={() => {
                              setSelectedActionItem({
                                ...item,
                                isLegacy: recording.isLegacy,
                              });
                              setIsDrawerOpen(true);
                            }}
                          >
                            <div className="flex-grow min-w-0">
                              <div className="text-sm text-gray-900">
                                {item.title} (
                                {formatTimeFromSeconds(item.startTime)})
                              </div>
                              <div className="mt-1 text-xs text-gray-500">
                                <span>
                                  Assignee: {item.assignee || "No assignee"}
                                </span>
                                <span className="mx-2">•</span>
                                <span>
                                  Due:{" "}
                                  {formatDueDate(item.due_date) ||
                                    "Not specified"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="text-sm text-gray-500 p-3">
                        No action items found for this meeting
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="flex flex-col items-center justify-center h-full text-brand-gray-lighter1">
              <svg
                className="h-12 w-12 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
              <div>No meetings found</div>
            </div>
          )}
        </div>
      </div>

      <EditActionItemDrawer
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedActionItem(null);
        }}
        actionItem={selectedActionItem}
        meetingUUID={selectedActionItem?.recordingUUID} // Use the UUID from the selected recording
        onActionItemChange={handleActionItemChange}
        currentActionItems={recordings.flatMap((recording) =>
          recording.actionItems.filter(
            (item) => item.title && item.title.trim().length > 0
          )
        )}
        isLegacyFormat={selectedActionItem?.isLegacy}
      />
    </div>
  );
}
